/* Common */

$textColor:#212121;
$textSecondaryColor:#757575;
$fontSize:14px;
$fontFamily:"Roboto","Helvetica Neue",sans-serif;
$lineHeight:18px;
$transitionDuration:.3s;
$borderRadius:3px;
$inputOptionPadding:.625em .875em;
$hoverBgColor:#e8e8e8;
$hoverTextColor:#000000;
$menuTextColor: #ffffff;
$menuIconColor: #DEDEDE;
$activeMenuItemBadgeColor:#ffffff;
$activeMenuItemBadgeTextColor:#212121;
$dividerColor:#bdbdbd;
$letterSpacing:.25px;
$iconPrefix: 'pi-md';
$overlayMaskBgColor:#424242;