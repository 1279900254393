.layout-wrapper {
       .layout-main {
        @include transition(margin-left $transitionDuration);
        @include box-sizing(border-box);

        .layout-topbar {
            height: 64px;
            background-color: $primaryColor;
            // padding: 16px 42px 16px 24px;
            padding: 16px 5px 16px 5px;
            position: fixed;
            width: 100%;
            @include transition(width $transitionDuration);
            @include multi-shadow(0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14));
            @include box-sizing(border-box);
            z-index: 999997;

            .menu-btn {
                cursor: pointer;
                display: none;
                color: $topbarIconColor;
                float: left;

                i {
                    font-size: 32px;
                }
            }

            .topbar-menu-btn {
                cursor: pointer;
                display: none;
                color: $topbarIconColor;
                float: right;

                i {
                    font-size: 32px;
                }
            }

            .mobile-logo {
                display: none;
                height: 48px;
                margin-top: -8px;
            }

            .layout-topbar-menu-mobile-wrapper {
                @media(min-width: 961px) {
                    display: none;
                }
                position: relative;
                color: #ffffff;
                &-title {
                    font-size: 24px;
                }
                &-menubtn {
                   position: absolute;
                   right: 0;
                   top: -2px;
                   color: #ffffff;
                   font-size: 22px;
                }
            }

            .layout-topbar-menu-wrapper {
                @media(max-width: 960px) {
                    display: none;
                }
                .topbar-menu {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    vertical-align: middle;
                    margin: 0;
                    -webkit-animation-duration: 0s;
                    -moz-animation-duration: 0s;
                    animation-duration: 0s;
                    @include clearfix();

                    .topbar-badge {
                        width: 16px;
                        height: 16px;
                        text-align: center;
                        background-color: $accentColor;
                        color: $accentTextColor;
                        font-size: $fontSize - 2;
                        font-weight: 700;
                        line-height: 16px;
                        @include border-radius(50%);
                    }

                    > li {
                        cursor: pointer;
                        float: right;
                        position: relative;
                        margin-left: 20px;

                        > button {
                            width: 100%;
                            color: $topbarTextColor;
                            position: relative;
                            overflow: visible;

                            &:focus {
                                outline: 0;
                            }

                            .topbar-item-name {
                                display: none;
                            }

                            i {
                                font-size: 32px;
                                color: $topbarTextColor;
                                @include transition(color $transitionDuration);

                                &:hover {
                                    color: darken($topbarTextColor, 10%);
                                    cursor: pointer;
                                }
                            }

                            .topbar-badge {
                                position: absolute;
                                right: -4px;
                            }
                        }

                        &.profile-item {
                            float: left;
                            margin-left: 0;
                            padding-top: 4px;
                            cursor: pointer;

                            > button {
                                display: inline-block;
                                position: relative;
                                top: -10px;
                                width: 100%;
                                color: $topbarTextColor;
                                font-size: $fontSize;
                                font-weight: $fontFamily;

                                .profile-image-wrapper {
                                    display: inline-block;
                                    vertical-align: middle;
                                    border: 2px solid transparent;
                                    width: 44px;
                                    height: 44px;
                                    @include border-radius(50%);
                                    @include transition(border-color $transitionDuration);

                                    img {
                                        width: 40px;
                                        height: 40px;
                                    }
                                }

                                .profile-name {
                                    display: inline-block;
                                    margin-left: 6px;
                                    vertical-align: middle;
                                    margin-top: -4px;
                                }

                                &:hover {
                                    .profile-image-wrapper {
                                        border-color: $accentColor;
                                    }
                                }
                            }

                            > ul {
                                right: auto;
                                left: 5px;

                                &:before {
                                    left: 8px;
                               }
                            }
                        }

                        &.search-item {
                            position: relative;
                            display: inline-block;
                            vertical-align: middle;
                            height: 40px;
                            @include box-sizing(border-box);

                            input {
                                border: 0 none;
                                width: 150px;
                                padding: 6px 24px 6px 6px;
                                background-color: transparent;
                                background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, lighten($primaryColor,25%),  lighten($primaryColor,25%));
                                border-width: 0;
                                outline: 0 none;
                                color: $topbarTextColor;

                                &:focus {

                                    label {
                                        color: $topbarTextColor;
                                    }
                                }
                            }

                            i {
                                position: absolute;
                                right: 0;
                                top: 0;
                                color: $topbarTextColor;
                                font-size: 28px;
                            }

                            label {
                                color: $topbarTextColor;
                                margin-top: 6px;
                            }

                            input:focus ~ i {
                                color: $primaryTextColor;
                            }
                        }

                        > ul {
                            position: absolute;
                            top: 60px;
                            right: 5px;
                            display: none;
                            width: 250px;
                            background-color: $topbarMenuBgColor;
                            -webkit-animation-duration: .5s;
                            -moz-animation-duration: .5s;
                            animation-duration: .5s;
                            list-style-type: none;
                            margin: 0;
                            padding: 8px 0;
                            border-top: 4px solid $primaryColor;
                            @include overlay-content-shadow();

                            button {
                                padding: 10px 10px 10px 10px;
                                display: block;
                                width: 100%;
                                box-sizing: border-box;
                                color: $textColor;
                                font-size: $fontSize;
                                font-weight: $fontFamily;

                                i {
                                    color: $textSecondaryColor;
                                    margin-right: 8px;
                                }

                                img {
                                    margin-right: 8px;
                                }

                                i,img,span {
                                    vertical-align: middle;
                                    display: inline-block;
                                }

                                .topbar-submenuitem-badge {
                                    background-color: $accentColor;
                                    padding: 2px 4px;
                                    display: block;
                                    font-size: 12px;
                                    @include border-radius($borderRadius);
                                    color: $accentTextColor;
                                    float: right;
                                }

                                &:hover {
                                    background-color: $topbarSubmenuHoverBgColor;
                                    @include transition(background-color $transitionDuration);

                                    i {
                                        color: $textColor;
                                    }
                                }
                            }

                            &:before {
                                width: 0;
                                height: 0;
                                border-left: 8px solid transparent;
                                border-right: 8px solid transparent;
                                border-bottom: 15px solid $primaryColor;
                                content: " ";
                                position: absolute;
                                top: -15px;
                                left: 232px;
                           }
                        }

                        &.active-topmenuitem {
                            > ul {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        .layout-breadcrumb {
            background-color: $breadcrumbBgColor;
            @include shadow(inset 0 -2px 4px 0 rgba(0, 0, 0, 0.14));
            min-height: 42px;
            padding-top: 64px;
            @include clearfix();

            ul {
                margin: 8px 0 0 0;
                padding: 0 0 0 20px;
                list-style: none;
                color: $textSecondaryColor;
                display: inline-block;

                li {
                    display: inline-block;
                    vertical-align: middle;
                    color: $textSecondaryColor;
                    text-transform: capitalize;

                    &:first-child {
                        margin-right: 4px;

                        button {
                            margin-top: 2px;
                            font-size: 1em;
                        }
                    }

                    button {
                        display: inline-block;
                        color: $textSecondaryColor;
                    }
                }
            }

            .layout-breadcrumb-options {
                float: right;
                padding: 0px 20px 0 0;
                height: 100%;

                button {
                    color: $textSecondaryColor;
                    display: inline-block;
                    width: 42px;
                    height: 42px;
                    line-height: 42px;
                    text-align: center;
                    cursor: pointer;
                    font-size: 1em;
                    @include transition(background-color $transitionDuration);

                    &:hover {
                        background-color: $hoverBgColor;
                    }

                    i {
                        line-height: inherit;
                    }
                }
            }
        }

        .layout-content {
            padding: 90px 17px 24px 17px;
            @media(max-width: 960px) {
                padding: 80px 0 20px 0;
            }
            &.register {
                padding: 30px 17px 24px 17px;

                @media(max-width: 960px) {
                    padding: 20px 0 20px 0;
                }
            }
        }

        .layout-main-mask {
            display: none;
        }

        .layout-footer {
            padding: 16px 24px;
            border: 0 none;
            border-top: 1px solid $dividerColor;
            background: $footerBgColor;

            img {
                margin-top: 5px;
                width: 100px;
            }

            .footer-text-right {
                float: right;
                margin-top: 10px;

                span {
                    vertical-align: middle;
                }
            }
        }
    }
}

.layout-wrapper-static {
    .layout-main {
        margin-left: 240px;

        .layout-topbar {
            width: calc(100% - 240px);
        }
    }
}


.deletion {
    margin: 5px;
    p {
        font-size: 14px;
        color: #232323;
        line-height: 18px;
        text-align: center;
    }
    h1 {
        font-size: 18px;
        line-height: 25px;
        height: 25px;
        text-align: center;
        color: #DE1211;
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
        @media(max-width: 960px) {
            height: 70px;
        }
    }
}

body .p-component-overlay {
    @media(min-height: 960px) {
        z-index: 1000000 !important;
    }
}

.p-sidebar-right {
    width: 100vw;
    margin-top: 4rem;
}

.forgottenlink {
    width: 96%;
    text-align: right;
    font-size: 10px;
    margin: 0;
}

.avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: solid 2px white;
    transform: translateY(-8px);
}

.menumobile {
    width: 90vw;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    align-items: center;
    justify-content: center;
}

.notify_editor {
    width: 40vw;
    @media(max-width: 960px) {
        width: 75vw;
    }
    @media(max-width: 720px) {
        width: 100vw;
    }
}


.form_editor, .notification_editor {
    width: 60vw;
    @media(max-width: 960px) {
        width: 75vw;
    }
    @media(max-width: 720px) {
        width: 100vw;
    }
}