.login-body {
    background-image: url("../images/login/login-bg.jpg");
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    footer {
        position: absolute;
        bottom: 0;
        background: rgba(122, 255, 255, 0.5);
    }

    .login-panel {
        width: 550px;
        height: 480px;
        background-color: #ffffff;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -275px;
        margin-top: -240px;
        padding: 0;
        @include border-radius(2px);
        @include multi-shadow(0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14));

        .login-panel-header {
            background-color: $primaryColor;
            text-align: center;
            padding: 8px 14px;

            img {
                vertical-align: middle;
                width: 50px;
            }
        }

        .login-panel-content {
            padding: 50px 100px;

            h1 {
                font-size: 16px;
                margin-top: 0;
                margin-bottom: 16px;
                text-align: center;
            }

            .p-col-12, .p-col-6 {
                padding: 1em;

                &:last-child {
                    text-align: center;

                    a {
                        color: $accentColor;
                    }
                }
            }

            .password-reset {
                text-align: right;

                a {
                    color: $textSecondaryColor;
                }
            }

            .p-checkbox-label {
                margin: 0 0 0 8px;
                vertical-align: middle;
            }

            .p-inputtext {
                width: 100%;
            }

            .p-button {
                width: 100%;

                .p-button-icon-left {
                    vertical-align: sub;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .login-body {
        .login-panel {
            left: 0;
            margin-left: 0;
            width: 100%;
            
            .login-panel-content {
                padding: 50px 25px;
            }
        }
    }
}

.goback {
    text-align: right;
    a {
        font-size: 25px;
        text-decoration: underline;
        padding-right: 5px;
        line-height: 30px;
        height: 30px;
    }
}

.buttonsBar {
    display: flex;
    flex-direction: row-reverse;
}

.p-grid.messages {
    height: 60px;
    width: 100%;
    padding: 5px 14px;
    & > div {
        width: 100%;
    }
}

@keyframes appear_actionbar {
    from {
        filter: blur(5px);
        opacity: 0;
    }
    to {
        filter: blur(0);
        opacity: 1;
    }
}

.actionbar {
    margin: 10px 0;
    filter: blur(5px);
    opacity: 0;
    @media(max-width: 960px) {

        position: relative;
        z-index: 100000;
    }
    animation: appear_actionbar 0.25s ease-out forwards;
    animation-delay: 0.25s;
    .desktop {
        @media(max-width: 960px) {
            display: none;
        }
    }
    .mobile {
        @media(min-width: 961px) {
            display: none;
        }
    }
}

.visualizer_query {
    margin: 10px 0;
    height: 20px;
    @media(max-width: 960px) {
        font-size: 10px;
    }
    & .lbl {
        font-size: 15px;
        letter-spacing: 0px;
        @media(max-width: 960px) {
            display: none;
        }
    }
    & code {
        letter-spacing: 0px;
        padding-top: 0.2px;
    }
}