@keyframes welcome {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

$nbDelays: 200;

@mixin delay-list {
  @for $i from 1 through $nbDelays {
      .delay-#{$i}-s { 
          animation-delay: #{$i / 5}s !important;
       }
  }
}

@include delay-list;

@keyframes appear {
    from {
        opacity: 0;
        max-height: 0;
    }
    to {
        opacity: 1;
        max-height: 12rem;
    }
}

body {
    .register {
        & > .p-grid {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            animation: appear 0.25s linear forwards;
            animation-delay: 0.05s;
        }
        .card.card-w-title {
            transform: translateY(50px);
            opacity: 0.1;
            animation: welcome 0.25s ease-out forwards;
            h1 {
                margin: 10px 0 20px 13px;
            }
        }
    }
    .editPage {
        .p-grid h1 {
            margin: 10px;
            @media(max-width: 960px) {
                font-size: 24px;
            }
        }
        .p-grid p {
            margin: 13px;
            letter-spacing: 0px;
            @media(max-width: 960px) {
                font-size: 12px;
            }
        }
        h4 {
            margin: 10px 15px;
            letter-spacing: -0.1px;
        }
        .card.card-w-title {
            transform: translateY(50px);
            opacity: 0.1;
            animation: welcome 0.25s ease-out forwards;
            h1 {
                margin: 10px 0 20px 13px;
                @media(max-width: 960px) {
                    font-size: 20px;
                }
            }
        }
        
    }
    .errorsPossibles {
        margin: 15px 0;
        & p {
            text-align: center;
            width: 100%;
            font-size: 14px;
            line-height: 16px;
            height: 16px;
        }
    }
    .p-inputtext {
        background: transparent no-repeat;
        background-image: linear-gradient(to bottom, $primaryColor, $primaryColor), linear-gradient(to bottom, $inputBorderColor, $inputBorderColor);
        background-size: 0 2px, 100% 1px;
        background-position: 50% 100%, 50% 100%;
        transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
        border-width: 0;
        font-size: 1em;
        padding: 2px;
        @include border-radius(0px);

        &:focus,
        &.p-focus {
            border-width: 0;
            background-size: 100% 2px, 100% 1px;
            padding-bottom: 2px;
        }

        &:disabled {
            border-bottom: 1px dotted;
        }

        &.p-widget-content {
            border-width: 1px;
            background: transparent;
            background-image: none;
        }

        &.p-error {
            border-color: $inputInvalidBorderColor;
            background-image: linear-gradient(to bottom, $inputInvalidBorderColor, $inputInvalidBorderColor), linear-gradient(to bottom, $inputInvalidBorderColor, $inputInvalidBorderColor);
        }
    }

    .p-multiselect-filter-container, .p-listbox-filter-container, .p-dropdown-filter-container, .p-datatable-header {
        .p-inputtext {
            background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, desaturate(lighten($primaryColor,25%), 25%), desaturate(lighten($primaryColor,25%), 25%));
            padding-bottom: 2px;
        }
    }


    .p-float-labels {
        input:focus ~ label,
        input.p-filled ~ label,
        .p-inputwrapper-focus ~ label,
        .p-inputwrapper-filled ~ label {
            font-size:12px;
            color:$primaryColor;
        }
    }

    .txtarea {
        margin-top: 20px;
    }

    .md-inputfield {
        display: block;
        position:relative;
        min-height: 40px;
        > input, > textarea {
            width: 100%;
        }
        > textarea {
            padding-top: 20px;
        }
        > label {
            color:#999;
            font-weight:normal;
            position:absolute;
            pointer-events:none;
            left:5px;
            top:-2px;
            transition: $transitionDuration ease all;
            -moz-transition: $transitionDuration ease all;
            -webkit-transition: $transitionDuration ease all;
        }

        input:focus ~ label,
        input.p-filled ~ label,
        .p-inputwrapper-focus ~ label,
        .p-inputwrapper-filled ~ label,
        input:-webkit-autofill ~ label {
            top:-20px;
            left: 0;
            font-size:12px;
            color:$primaryColor;
        }

        .input:-webkit-autofill ~ label {
            top:-20px;
            font-size:12px;
            color:$primaryColor;
        }

        input.ng-dirty.ng-invalid ~ label {
            color: $inputErrorTextColor;
        }

        .p-message {
            &.p-messages-error {
                background-color: transparent;
                border: 0 none;
                margin: 0px;
                color: $inputErrorTextColor;
                font-size: $errorMessageFontSize;
            }
        }

        &.md-inputfield-fill {
            input {
                background-color: $textboxBgColor;
                padding-left: 4px;
                padding-right: 4px;
                padding-top: 4px;
            }

            label {
                top: 2px;
            }
        }

        &.md-inputfield-box {
            input {
                background-color: $textboxBgColor;
                padding-left: 4px;
                padding-right: 4px;
                padding-top: 24px;
            }

            label {
                top: 23px;
            }

            input:focus ~ label,
            input.p-filled ~ label,
            .p-inputwrapper-focus ~ label,
            .p-inputwrapper-filled ~ label {
                top:1px;
            }

            .input:-webkit-autofill ~ label {
                top:1px;
            }
        }
    }

    .p-material-message {
        &.p-message {
            &.p-messages-error {
                background-color: transparent;
                border: 0 none;
                margin: 0px;
                color: #e62a10;
                font-size: .75em;
            }
        }
    }

    .p-listbox {
        @include content();
        @include corner-all();
        min-width: 12.5em;
        background-color: $inputBgColor;
        border: 0 none;
        @include border-radius($borderRadius);

        &.p-inputtext {
            padding: 0;
        }

        .p-listbox-list {
            padding: $inputListPadding;
            border: 1px solid $contentBorderColor;
        }

        .p-listbox-item {
            overflow: hidden;
            padding: $listItemPadding;
            margin: 0;
            @include rippleitem();
            @include border-radius(0);


            &.p-highlight {
                background-color: $accentColor;
                color: $accentTextColor;
            }
        }

        &:not(.p-disabled) {
            .p-listbox-item {
                &:not(.p-highlight):hover {
                    @include hover-element();
                }
            }
        }

        .p-listbox-header {
            @include header();
            @include border-radius(0);
            margin-bottom: 0;
            padding: $inputHeaderPadding;
            background-color: $primaryColor;
            @include border-radius-top($borderRadius);

            .p-checkbox {
                .p-checkbox-box {
                    border-color: darken($headerTextColor, 15%);
                }
            }

            .p-listbox-filter-container {
                .p-inputtext {
                    border-color: darken($headerTextColor, 15%);
                    color: $headerTextColor;
                    width: 100%;
                    padding: 2px;
                    padding-left: 1.5em;
                    @include border-box-sizing();

                    &:focus {
                        border-color: $headerTextColor;
                    }

                }

                .pi {
                    top: -2px;
                    left: 0;
                }
            }
        }
    }

    .p-button {
        @include corner-all();
        overflow: hidden;
        background-color: $primaryColor;
        color: $buttonTextColor;
        font-size: 1em;
        height: 2.143em;
        font-family: $fontFamily;
        padding: 0 1em;
        border: 0 none;
        -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        @include transition(background-color $transitionDuration);

        &:enabled:not(:focus):not(.p-dataview-header .p-highlight):hover {
            background-color: $primaryDarkColor;
        }

        &:focus {
            outline: 0 none;
            background-color: lighten($primaryColor,10%);
        }

        .p-button-text {
            padding: 0;
            line-height: 2.143em;
        }

        .pi {
            color: $buttonTextColor;
        }

        &.p-button-icon-only {
            @include border-radius(50%);
            width: 2.143em;
            height: 2.143em;
        }

        &.p-button-text-icon-left {
            padding-left: 2.571em;
        }

        &.p-button-text-icon-right {
            padding-right: 2.571em;
        }

        &.secondary-btn {
            background-color: $accentColor;
            color: $accentTextColor;

            &:enabled:not(:focus):hover {
                background-color: $accentDarkColor;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($accentColor,10%);
            }
        }

        &.blue-grey-btn {
            background-color: #607D8B;

            &:enabled:not(:focus):hover {
                background-color: #37474F;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten(#607D8B,10%);
            }
        }

        &.cyan-btn {
            background-color: #00BCD4;

            &:enabled:not(:focus):hover {
                background-color: #00838F;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten(#00BCD4,10%);
            }
        }

        &.teal-btn {
            background-color: #009688;

            &:enabled:not(:focus):hover {
                background-color: #00695C;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten(#009688,10%);
            }
        }

        &.red-btn {
            background-color: #F44336;

            &:enabled:not(:focus):hover {
                background-color: #C62828;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten(#F44336,10%);
            }
        }

        &.green-btn {
            background-color: #4CAF50;

            &:enabled:not(:focus):hover {
                background-color: #2E7D32;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten(#4CAF50,10%);
            }
        }

        &.deep-orange-btn {
            background-color: #FF5722;

            &:enabled:not(:focus):hover {
                background-color: #D84315;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten(#FF5722,10%);
            }
        }

        &.purple-btn {
            background-color: #673AB7;

            &:enabled:not(:focus):hover {
                background-color: #4527A0;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten(#673AB7,10%);
            }
        }

        &.pink-btn {
            background-color: #E91E63;

            &:enabled:not(:focus):hover {
                background-color: #AD1457;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten(#E91E63,10%);
            }
        }

        &.amber-btn {
            background-color: #FFC107;
            color: #212121;

            &:enabled:not(:focus):hover {
                background-color: #FF8F00;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten(#FFC107,10%);
            }
        }

        &.orange-btn {
            background-color: #FF9800;

            &:enabled:not(:focus):hover {
                background-color: #EF6C00;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten(#FF9800,10%);
            }
        }

        &.brown-btn {
            background-color: #795548;

            &:enabled:not(:focus):hover {
                background-color: #4E342E;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten(#795548,10%);
            }
        }

        &.indigo-btn {
            background-color: #3F51B5;

            &:enabled:not(:focus):hover {
                background-color: #283593;
            }
            &:focus {
                outline: 0 none;
                background-color: lighten(#3F51B5,10%);
            }
        }

        &.flat-btn {
            @include no-shadow();
        }
    }

    .p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button  {
        color: $textColor;
        background-color: #ffffff;

        .p-button-icon-left {
            color: $textSecondaryColor;
        }

        .p-button-icon-right {
            color: $textSecondaryColor;
        }

        &:enabled:hover {
            @include hover-element();
        }

        &:enabled:focus {
            background-color: darken(#ffffff, 10%);
        }
    }

    .p-button.p-button-info, .p-buttonset.p-button-info > .p-button  {
        color: #ffffff;
        background-color: #03A9F4;

        &:enabled:hover {
            background-color: #0277BD;
        }

        &:enabled:focus {
            outline: 0 none;
            background-color: lighten(#03A9F4,10%);
        }
    }

    .p-button.p-button-success, .p-buttonset.p-button-success > .p-button  {
        color: #ffffff;
        background-color: #4CAF50;

        &:enabled:hover {
            background-color: #2E7D32;
        }

        &:enabled:focus {
            outline: 0 none;
            background-color: lighten(#4CAF50,10%);
        }
    }

    .p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button  {
        color: #212121;
        background-color: #FFC107;

        &:enabled:hover {
            background-color: #FF8F00;
        }

        &:enabled:focus {
            outline: 0 none;
            background-color: lighten(#FFC107,10%);
        }
    }

    .p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button  {
        color: #ffffff;
        background-color: #F44336;

        &:enabled:hover {
            background-color: #C62828;
        }

        &:enabled:focus {
            outline: 0 none;
            background-color: lighten(#F44336,10%);
        }
    }

    .p-buttonset {
        .p-button {
            @include border-radius(0);

            &:first-child {
                @include border-radius-left($borderRadius);
            }

            &:last-child {
                @include border-radius-right($borderRadius);
            }
            &:not(.p-disabled):not(.p-highlight):hover {
                background-color: $primaryDarkColor;
            }

            &.p-highlight {
                background-color: $accentColor;
                color: $accentTextColor;
            }
        }
    }

    .p-togglebutton {
        &:not(.p-highlight):not(.p-disabled):hover {
            background-color: $primaryDarkColor;
        }

        &.p-highlight {
            background-color: $accentColor;
            color: $accentTextColor;
        }
    }

    .p-splitbutton {
        @include border-radius($borderRadius + 1);
        -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);

        > .p-button {
            @include no-shadow();
            vertical-align: top;

            &:first-child {
                vertical-align: top;
                @include corner-left();
                @include border-radius-left($borderRadius);
                @include border-radius-right(0);
            }

            &:active {
                background-color: lighten($primaryColor,10%);
            }

            &.p-splitbutton-menubutton {
                height: 2.143em;
                @include border-radius-left(0);
                @include border-radius-right(3px);
            }
        }

        .p-menu {
            width: 100%;
            min-width: 12.5em;
        }
    }

    .p-selectbutton {
        .p-button {
            &:not(.p-disabled):not(.p-highlight):hover {
                background-color: $primaryDarkColor;
            }

            &:not(.p-disabled):not(.p-highlight):focus {
                outline: 0 none;
                background-color: lighten($primaryColor,10%);
            }
        }
    }

    .p-fluid {
        .p-splitbutton {
            .p-button:first-child {
                width: calc(100% - 2.5em);
            }

            .p-button.p-splitbutton-menubutton {
                width: 2.5em;
            }
        }
    }

    .p-checkbox {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.429em;
        width: 1.429em;
        height: 1.429em;
        cursor: default;
        margin: 0 .5em 0 0;

        .p-checkbox-box {
            border: 2px solid $checkboxBorderColor;
            width: 1.429em;
            height: 1.429em;
            position: relative;
            @include transition(background-color .3s);

            &:before {
                position: absolute;
                left: 6px;
                top: 6px;
                content: "";
                background-color: $primaryLightColor;
                height: 4px;
                width: 4px;
                border-radius: 100%;
                z-index: 1;
                opacity: 0;
                margin: 0;
            }

            .p-checkbox-icon {
                font-size: 1.25em;
                margin-left: -1px;
                margin-top: -1px;
            }

            &.p-highlight {
                border-color: $primaryColor;
                background-color: $primaryColor;

                .ui-checkbox-icon {
                    color: $primaryTextColor;
                }

                &:before {
                    -webkit-animation: rippleOn 400ms forwards ease-out;
                    animation: rippleOn 400ms forwards ease-out;
                }
            }

            &.p-focus {
                border-color: $primaryColor;
                @include content-shadow();
                @include transition(box-shadow$transitionDuration, background-color$transitionDuration);
            }
        }
    }

    .p-radiobutton {
        position: relative;
        margin: 0 .5em 0 0;
        display: inline-block;
        vertical-align: middle;
        width: 1.429em;
        height: 1.429em;

        .p-radiobutton-box {
            border: 2px solid $radioButtonBorderColor;
            width: 1.286em;
            height: 1.286em;
            @include transition(box-shadow .3s);
            @include border-radius(50%);

            &:before {
                position: absolute;
                left: 8px;
                top: 8px;
                content: "";
                background-color: $primaryLightColor;
                height: 4px;
                width: 4px;
                border-radius: 100%;
                z-index: 1;
                opacity: 0;
                margin: 0;
            }

            &.p-focus {
                border-color: $primaryColor;
                @include content-shadow();
                @include transition(box-shadow$transitionDuration,background-color$transitionDuration);
            }

            &.p-highlight {
                border-color: $primaryColor;
                background-color: transparent;

                &:before {
                    -webkit-animation: rippleOn 400ms forwards ease-out;
                    animation: rippleOn 400ms forwards ease-out;
                }
            }

            .p-radiobutton-icon {
                display: block;
                box-sizing: border-box;
                @include border-radius(50%);
                font-size: 1em;
                transition: -webkit-transform ease .28s;
                transition: transform ease .28s;
                -webkit-transform: scale(0);
                transform: scale(0);
                margin: 0;
            }

            .pi-circle-on {
                &.pi:before {
                    content: "circle";
                }
                background-color: $primaryColor;
                -webkit-transform: scale(0.6);
                transform: scale(0.6);
                top: 50%;
                left: 50%;
                margin-top: -.5em;
                margin-left: -.5em;
            }
        }
    }

    .p-autocomplete-panel {
        @include content();
        @include corner-all();
        padding: 0;
        border: 0 none;
        @include border-radius(0);
        @include overlay-input-shadow();

        .p-autocomplete-list {
            padding: $inputListPadding;

            .p-autocomplete-list-item {
                padding: $listItemPadding;
                color: $textColor;
                margin: 0;
                @include border-radius(0);

                .p-autocomplete-query {
                    font-weight: 700;
                }

                &:hover, &.p-highlight {
                    background-color: $accentColor;
                    color: $accentTextColor;
                }
            }

            .p-autocomplete-group {
                padding: $listItemPadding;
            }
        }
    }

    .p-autocomplete {

        .p-autocomplete-loader {
            @include material-icon('refresh');
            @include animation(0, 1000ms, spin);
            color: $textSecondaryColor;
            right: 0;
        }

        &.p-autocomplete-dd {
            .p-autocomplete-loader {
                right: 1em;
            }
        }

        .p-autocomplete-dropdown {
            background-color: transparent;
            @include no-shadow();
            height: 1.429em;
            width: 1.429em;
            padding: 0;
            right: 0;
            bottom: 0;
            margin-right: 0;
            position: absolute;

            .p-button-text {
                display: none;
            }

            .pi {
                font-size: $iconFontSize;
                color: $textSecondaryColor;
            }
        }

        &.p-autocomplete-multiple {
            .p-autocomplete-multiple-container {
                &.p-inputtext {
                    @include border-box-sizing();
                    padding: 2px 2px 1px 2px;
                }

                &.p-disabled {
                    border-bottom: 1px dotted;
                }
            }

            .p-autocomplete-input-token {
                float: none;
                display: inline-block;
                margin: 0 1px;
                vertical-align: middle;

                > input {
                    padding: 0;
                    margin: 0;
                    font-size: 1em;
                }
            }

            .p-autocomplete-token {
                display: inline-block;
                float: none;
                vertical-align: middle;
                font-size: 1em;
                @include corner-all();

                .p-autocomplete-token-label {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            .p-autocomplete-dropdown.p-button.p-button-icon-only {
                bottom: 3px;
            }

            .p-autocomplete-loader {
                margin-top: -.35em;
            }
        }
    }

    .p-fluid {
        .p-autocomplete {
            &.p-autocomplete-dd {
                > .p-inputtext {
                    width: 100%;
                }
            }
        }
    }

    .p-dropdown {
        @include corner-all();
        border-width: 0;
        background: transparent no-repeat;
        background-image: linear-gradient(to bottom, $primaryColor, $primaryColor), linear-gradient(to bottom, $inputBorderColor, $inputBorderColor);
        background-size: 0 2px, 100% 1px;
        background-position: 50% 100%, 50% 100%;
        transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
        padding-bottom: 2px;
        box-sizing: border-box;
        @include border-radius(0);

        .p-dropdown-trigger-icon {
            height: 1.5em;
            width:  1.5em;
            margin-top: 0;
            padding: 0;
            top: 2px;
            margin-right: 0;

            .pi {
                font-size: $iconFontSize;
                color: $textSecondaryColor;
            }
        }

        .p-dropdown-label {
            background: none;
            &.p-inputtext {
                font-family: $fontFamily;
            }
        }

        &:not(.p-disabled).p-focus {
            border-width: 0;
            background-size: 100% 2px, 100% 1px;
            padding-bottom: 2px;
            outline: none;

            .p-dropdown-label {
                padding-bottom: 2px;
            }
        }
    }

    .p-dropdown-panel {
        @include content();
        @include corner-all();
        padding: 0;
        @include border-radius(0);
        @include overlay-input-shadow();

        .p-dropdown-list {
            padding: $inputListPadding;
        }

        .p-dropdown-item {
            margin: 0;
            padding: $listItemPadding;
            color: $textColor;
            @include border-radius(0);

            &:not(.p-highlight):not(.p-disabled):hover {
                @include hover-element();
            }

            &.p-highlight {
                background-color: $accentColor;
                color: $accentTextColor;
            }
        }

        .p-dropdown-filter-container {
            display: block;
            border-bottom: 1px solid $inputBorderColor;
            background-color: $primaryColor;
            padding: $inputHeaderPadding;

            input {
                color: #ffffff;

                &:focus {
                    border-bottom-color: #ffffff;
                }
            }

            .pi {
                top: .325em;
                right: .75em;
                color: $primaryTextColor;
            }
        }
    }

    .p-multiselect {
        border-bottom: 1px solid $inputBorderColor;
        box-sizing: border-box;
        @include border-radius(0);
        @include transition(border-color .3s);

        .p-multiselect-label-container {
            padding-right: 1.5em;

            .p-multiselect-label {
                padding: 2px;
            }
        }

        .p-multiselect-trigger-icon {
            height: 1.5em;
            width:  1.5em;
            margin-top: 0;
            padding: 0;
            top: -2px;

            .pi {
                font-size: $iconFontSize;
                color: $textSecondaryColor;
            }
        }

        &:not(.p-disabled).p-focus {
            border-bottom: 2px solid $primaryColor;
        }
    }

    .p-multiselect-panel {
        @include content();
        @include corner-all();
        padding: 0;
        border: 0 none;
        @include border-radius(0);
        @include overlay-input-shadow();

        .p-multiselect-header {
            @include corner-all();
            @include header();
            @include border-radius(0);
            margin: 0;
            border: 0 none;
            padding: $inputHeaderPadding;
            position: relative;

            .p-checkbox {
                float: none;
                margin: 0 8px 0 1px;
                position: static;

                .p-checkbox-box {
                    border-color: $headerTextColor;

                    .p-checkbox-icon {
                        border-color: $headerTextColor;
                    }

                    &.p-highlight {
                        .p-checkbox-icon {
                            border-color: $headerTextColor;
                        }

                        &:before {
                            -webkit-animation: none;
                            animation: none;
                        }
                    }

                    &.p-focus {
                        background-color: $primaryLightColor;
                        @include transition(background-color .3s);
                    }
                }
            }

            .p-multiselect-filter-container {
                width: 70%;
                display: inline-block;
                vertical-align: middle;
                float: none;

                .pi {
                    top: -2px;
                    left: 0;
                    color: $headerTextColor;
                }

                .p-inputtext {
                    color: $headerTextColor;
                    padding: 2px;
                    padding-left: 1.5em;
                    width: 100%;
                    @include border-box-sizing();

                    &:focus {
                        border-bottom-color: #ffffff;
                    }
                }
            }

            .p-multiselect-close {
                position: absolute;
                right: .5em;
                top: .625em;
                height: 1.5em;
                width: 1.5em;

                span {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 1em;
                    height: 1em;
                    margin-top: -.5em;
                    margin-left: -.5em;
                    color: $primaryTextColor;;
                }

                &:hover {
                    background-color: $primaryLightColor;
                    @include border-radius(50%);
                    @include transition(background-color .3s);
                }
            }
        }

        .p-multiselect-items {
            padding: $inputListPadding;
            background-color: $contentBgColor;

            .p-multiselect-item {
                padding: 0.571em 1em;
                margin: 0;
                color: $textColor;
                @include border-radius(0);
                @include rippleitem();

                .p-checkbox {
                    .p-checkbox-box {
                        &.p-highlight {
                            &:before {
                                -webkit-animation: none;
                                animation: none;
                            }
                        }
                    }
                }

                &:not(.p-disabled):not(.p-highlight):hover {
                    @include hover-element();
                }

                &.p-highlight {
                    color: $accentTextColor;
                }
            }
        }
    }

    .p-spinner {
        @include corner-all();

        .p-spinner-button {
            width: 1.286em;
            height: 0.714em;
            padding: 0;
            margin-right: .25em;
            background-color: transparent;
            color: $textColor;
            z-index: auto;
            @include no-shadow();

            .pi-triangle-1-n {
                color: $textColor;
            }

            .pi-triangle-1-s {
                color: $textColor;
            }

            .pi {
                top: .25em;
                font-size: $iconFontSize;
                height: .5em;
                color: $textSecondaryColor;
            }

            &:enabled:hover,
            &:active {
                background-color: transparent;
            }
        }

        .p-spinner-up {
            .pi {
                top: .25em;
            }
        }

        .p-spinner-down {
            .pi {
                top: .167em;
            }
        }

        .p-spinner-input {
            padding-right: 30px;
        }
    }

    .p-fluid {
        .p-spinner {
            .p-spinner-button {
                width: 1.786em;
                height: 0.714em;

                .pi {
                    left: auto;
                }
            }
        }
    }

    .p-inputswitch {
        height: 14px;
        width: 34px !important;

        .p-inputswitch-slider {
            background-color: rgb(158,158,158);
            @include border-radius(8px);

            &:before {
                background-color: #ffffff;
                height: 1.429em;
                width: 1.429em;
                left: 0;
                bottom: -0.214em;
                border-radius: 50%;
                -webkit-transition: $transitionDuration;
                transition: $transitionDuration;
                -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
                -moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
            }
        }

        &.p-inputswitch-checked {
            .p-inputswitch-slider:before {
                -webkit-transform: translateX(1.250em);
                -ms-transform: translateX(1.250em);
                transform: translateX(1.250em);
            }
        }

        &.p-inputswitch-focus {
            .p-inputswitch-slider {
                background-color: rgb(158,158,158);
            }
        }

        &:not(.p-state-disabled):hover {
            .p-inputswitch-slider {
                background-color: rgb(158,158,158);
            }
        }

        &.p-inputswitch-checked {
            .p-inputswitch-slider {
                background-color: $accentLightColor;

                &:before {
                    background-color: $accentColor
                }
            }

            &:not(.p-state-disabled):hover {
                .p-inputswitch-slider {
                    background-color: $accentLightColor;
                }
            }

            &.p-inputswitch-focus {
                .p-inputswitch-slider {
                    background: $accentLightColor;
                }
            }
        }
    }

    .p-slider {
        @include content();
        @include corner-all();
        padding: 0;

        .p-slider-handle {
            background-color: $accentColor;
            color: $accentTextColor;
            @include border-radius(50%);
            width: 1.429em;
            height: 1.429em;
            transform: scale(.7);
            @include transition(all .4s cubic-bezier(.25,.8,.25,1));

            &.p-focus,
            &:hover {
                transform: scale(1);
            }

            &:focus {
                outline: 0 none;
            }
        }

        &.p-slider-horizontal {
            height: 2px;
            border: 0 none;
            background-color: $inputBorderColor;

            .p-slider-handle {
                top: -.65em;
            }
        }

        &.p-slider-vertical {
            width: 2px;
            border: 0 none;
            background-color: $inputBorderColor;

            .p-slider-handle {
                left: -0.643em;
            }
        }

        .p-slider-range {
            @include corner-all();
            padding: 0;
            background-color: $accentColor;
            color: $accentTextColor;
        }

        &:not(.p-disabled) {
            .p-slider-handle {
                &:hover {
                    transform: scale(1);
                }
            }
        }
    }

    .p-calendar {
        .p-datepicker {
            min-width: auto;
        }

        .p-datepicker-trigger.p-button-icon-only {
            position: absolute;
            bottom: 2px;
            right: 0;
            background-color: transparent;
            color: $textColor;
            height: 1.5em;
            width: 1.5em;
            @include no-shadow();
            @include border-radius(0);

            .pi {
                color: $textSecondaryColor;
            }

            &:enabled:focus {
                background-color: transparent;
            }

            &:enabled:hover {
                background-color: transparent;
            }
        }
    }

    .p-fluid {
        .p-calendar.p-calendar-w-btn {
            .p-inputtext {
                width: 100%;
            }
        }
    }

    .p-datepicker {
        @include content();
        @include corner-all();
        padding: 0;
        width: 24em;

        &.p-shadow {
            border: 0 none;
            @include overlay-input-shadow();
        }

        .p-datepicker-group {
            padding: 0;
        }

        &:not(.p-disabled) {
            .p-datepicker-header {
                .p-datepicker-prev,
                .p-datepicker-next {
                    &:hover {
                        background-color: $primaryColor;
                    }
                }
            }

            table {
                td {
                    span:not(.p-highlight):not(.p-highlight):hover {
                        @include hover-element();
                    }
                }
            }

            .p-monthpicker {
                .p-monthpicker-month:not(.p-highlight):hover {
                    @include hover-element();
                }
            }
        }

        .p-datepicker-header {
            @include header();
            @include corner-all();
            padding: $headerPadding;
            background: $primaryDarkColor;
            border-color: $primaryDarkColor;
            @include border-radius-top(2px);
            @include border-radius-bottom(0);

            .p-datepicker-prev {
                cursor: pointer;
                top: 12px;
                left: .5em;
                font-size: $iconFontSize;
                @include border-radius(50%);
                @include material-icon("\e5cb");
                color: $primaryTextColor;

                .pi {
                    display: none;
                }
            }

            .p-datepicker-next {
                cursor: pointer;
                top: 12px;
                font-size: $iconFontSize;
                right: .5em;
                @include material-icon("\e5cc");
                @include border-radius(50%);
                color: $primaryTextColor;

                .pi {
                    display: none;
                }
            }

            select.p-datepicker-month,
            select.p-datepicker-year {
                font-size: $fontSize - 2;
            }

            .p-datepicker-title {
                margin: 0;
                padding: 0;
                line-height: 1.8;

                select {
                    margin-top: -.35em;
                    margin-bottom: 0;
                }
            }
        }

        table {
            table-layout: fixed;
            border-spacing: 0;
            border-collapse: collapse;
            margin: 0;

            thead {
                tr {
                    color: $primaryTextColor;
                    background: $primaryColor;
                }
            }

            td {
                padding: .25em;
                box-sizing: border-box;

                &.p-datepicker-today {
                    > span {
                        color: $textColor;
                        background-color: $contentBgColor;
                        border: 1px solid $accentColor;

                        &.p-highlight {
                            background-color: $accentColor;
                            color: $accentTextColor;
                        }
                    }
                }

                > span {
                    padding: 0;
                    margin: 0;
                    text-align: center;
                    color: $textColor;
                    display: inline-block;
                    height: 3em;
                    width: 3em;
                    line-height: 3em;
                    @include border-radius(50%);

                    &.p-highlight {
                        background-color: $accentColor;
                        color: $accentTextColor;
                    }

                    span {
                        display: inline;
                        padding: 0;
                        text-align: center;
                    }
                }

            }
        }

        .p-timepicker {
            @include header();
            @include corner-all();
            @include border-radius(0);

            > div {
                a {
                    height: 1.5em;

                    > span {
                        display: inline-block;
                        margin-bottom: .25em;
                    }

                    .pi {
                        &.pi-angle-up {
                            @include rotate(0);
                            @include material-icon("keyboard_arrow_up");
                        }

                        &.pi-angle-down {
                            @include rotate(0);
                            @include material-icon("keyboard_arrow_down");
                        }
                    }
                }
            }

            button {
                color:$accentTextColor;
                font-size: $fontSize;
                opacity: .7;
                @include transition(color, $transitionDuration);

                &:hover {
                    opacity: 1;
                    color: $accentTextColor;
                }
            }
        }


        .p-monthpicker {
            .p-monthpicker-month {
                color: $textColor;

                &.p-highlight {
                    color: $accentTextColor;
                    background-color: $accentColor;
                }
            }
        }

        &.p-datepicker-multiple-month {
            width: auto;
            .p-datepicker-group {
                border-right: 1px solid $dividerColor;
                padding-top: 0;
                padding-bottom: 0;

                .p-datepicker-calendar-container {
                    tbody {
                        padding-right: .25em;
                        padding-left: .25em;
                    }
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                    border-right: 0 none;
                }
            }
        }
    }

    #p-datepicker-div {
        @include overlay-input-shadow();
    }

    .p-rating {
        font-size: inherit;
        min-height: 1.5em;

        .pi-ban {
            @include material-icon("cancel");
            color: $textSecondaryColor;
            font-size: $iconFontSize;
        }

        .pi-star {
            @include material-icon("star");
            color: $accentColor;
            font-size: $iconFontSize;
        }

        .pi-star-o {
            @include material-icon("star_border");
            color: $textSecondaryColor;
            font-size: $iconFontSize;
        }
    }

    .p-chips {
        > ul.p-inputtext {
            padding: 2px;

            .p-chips-input-token {
                padding-bottom: 2px;

                > input {
                    padding: 0;
                    margin: 0;
                    vertical-align: bottom;
                    background: transparent;
                }

                input.p-disabled {
                    width: 0;
                }
            }

            .p-chips-token {
                @include corner-all();
                display: inline-block;
                float: none;
                vertical-align: middle;
                font-size: 1em;

                .p-chips-token-label {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            &.p-disabled {
                border-bottom: 1px dotted;
            }
        }
    }

    .p-password-panel {
        @include corner-all();

        &.p-shadow {
            @include overlay-input-shadow();
        }
    }

    .p-fileupload {
        .p-fileupload-buttonbar {
            @include header();
            @include corner-top();
            padding: $headerPadding;

            .pi-arrowreturnthick-1-n {
                @include icon_override('file_upload');
            }

            .p-button {
                @include corner-all();
                background-color: $accentColor;
                color: $accentTextColor;
                margin-right: 6px;

                .pi {
                    color: $accentTextColor;
                }

                &:enabled:hover {
                    background-color: $accentDarkColor;
                }

                &.p-fileupload-choose {
                    .pi {
                        vertical-align: middle;
                    }
                }
            }
        }

        .p-fileupload-content {
            @include content();
            @include corner-bottom();

            .p-messages-error {
                .pi {
                    color: #ffffff;
                }
            }
        }
    }

    .p-fileupload-choose:not(.p-disabled):hover,
    .p-fileupload-choose.p-focus {
        &.p-button {
            background-color: darken($accentColor, 5%);
        }
    }

    .p-fileupload-choose:not(.p-disabled):active {
        &.p-button {
            background-color: darken($accentColor, 10%);
        }
    }

    .p-editor-container {
        .p-editor-toolbar {
            background-color: #e8e8e8;
        }

        .ql-picker.ql-expanded {
            .ql-picker-label {
                color: #444444;
            }
        }
    }

    /* InputGroup */
    .p-inputgroup {
        .p-inputgroup-addon {
            padding: $inputGroupPadding;
            border-color: $inputGroupBorderColor;
            background-color: $inputGroupBgColor;
            color: $inputGroupTextColor;
            min-width: $inputGroupAddonMinWidth;
            border-left: 0;
            border-right: 0;
            border-top: 0;

            &:first-child {
                @include border-radius-left(0);
            }

            &:last-child {
                @include border-radius-right($borderRadius);
            }

            > i {
                @include flex();
                align-self: flex-end;
            }
        }

        .p-inputtext {
            align-self: flex-end;
        }

        .md-inputfield {
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            width: 1%;
            display: inherit;

            label {
                top: 5px;
            }
        }

        .p-checkbox, .p-radiobutton {
            margin-bottom: .125em;
        }
    }

    .p-fluid {
        .p-inputgroup {
            .p-button-icon-only {
                width: 2.143em;
                height: 2.143em;
                padding: 0;

                .p-button-icon-left {
                    margin-left: -.45em;
                }
            }
        }
    }

    /* Validation */
    .p-inputtext.p-error,
    .p-dropdown.p-error,
    .p-autocomplete.p-error > .p-inputtext,
    .p-calendar.p-error > .p-inputtext,
    .p-chips.p-error > .p-inputtext,
    .p-checkbox.p-error > .p-checkbox-box,
    .p-radiobutton.p-error > .p-radiobutton-box,
    .p-inputswitch.p-error,
    .p-listbox.p-error,
    .p-multiselect.p-error,
    .p-spinner.p-error > .p-inputtext,
    .p-selectbutton.p-error > .p-button,
    .p-togglebutton.p-error > .p-button  {
        background-image: linear-gradient(to bottom, $inputInvalidBorderColor, $inputInvalidBorderColor), linear-gradient(to bottom, $inputInvalidBorderColor, $inputInvalidBorderColor);
    }

    .ui-fluid .ui-colorpicker-preview {
        width: 2em;
    }
}

@media (max-width: 640px) {
    body {
        .p-buttonset {
            > .p-button {
                display: block;
                @include border-radius(0);

                &:first-child {
                    @include border-radius-top($borderRadius);
                }

                &:last-child {
                    @include border-radius-bottom($borderRadius);
                }
            }
        }
        .p-splitbutton {
            > .p-button {
                display: inline-block;

                &:first-child {
                    @include border-radius-top(0);
                }

                &:last-child {
                    @include border-radius-bottom(0);
                }
            }
        }
    }
}
