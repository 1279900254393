/* Utils */
.clearfix:after {
	content: " ";
	display: block;
	clear: both;
}

*[hidden] {
	display: none;
}

.card {
	@include content-shadow();
	@include border-radius(2px);
	background: #ffffff;
	padding: 16px;
	margin-bottom: 16px;
	box-sizing: border-box;

	&.card-w-title {
		padding-bottom: 32px;
	}

	&.support_card {
		margin-top: 10px;
		padding-top: 50px;
	}

	h1 {
		font-size: 24px;
		font-weight: 400;
		margin: 24px 0;

		&:first-child {
			margin-top: 16px;
		}
	}

	h2 {
		font-size: 22px;
		font-weight: 400;
	}

	h3 {
		font-size: 20px;
		font-weight: 400;
	}

	h4 {
		font-size: 18px;
		font-weight: 400;
	}
	p {
		line-height: 18px;
		padding-bottom: 20px;
	}
}

.nopad {
	padding: 0;

	.p-panel-content {
		padding: 0;
	}
}

@-webkit-keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20px, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

@-webkit-keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}
}

@keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}
}

@keyframes rippleOn {
	0% {
		opacity: 0.5;
	}
	100% {
		opacity: 0;
		transform: scale(13, 13);
	}
}

@keyframes rippleOff {
	0% {
		opacity: 0.5;
	}
	100% {
		opacity: 0;
		transform: scale(13, 13);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.#{$iconPrefix}-spin {
	-webkit-animation-name: spin;
	animation-name: spin;
}

.fadeInDown {
	-webkit-animation: fadeInDown 5s; /* Safari 4.0 - 8.0 */
	animation: fadeInDown 5s;
}

.fadeOutUp {
	-webkit-animation: fadeOutUp $transitionDuration; /* Safari 4.0 - 8.0 */
	animation: fadeOutUp $transitionDuration;
}

.ui-shadow-1 {
	-webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	-moz-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.ui-shadow-2 {
	-webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	-moz-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.ui-shadow-3 {
	-webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	-moz-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.ui-shadow-4 {
	-webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	-moz-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.ui-shadow-5 {
	-webkit-box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
	-moz-box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
	box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.p-grid {
	-ms-flex-wrap: wrap;

	&.form-group {
		> div {
			padding: 1em;
		}
	}

	&.form-group-m {
		> div {
			padding: 1em;
		}
	}
}

.separator {
	border: 1px solid $dividerColor;
}

/* Ripple */
/* Ripple Effect Style like Google Material Buttons Effect*/
.ripplelink {
	/* display:block; */
	/*color:#fff;*/
	text-decoration: none;
	position: relative;
	overflow: hidden;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
	z-index: 0;
}

.ink {
	display: block;
	position: absolute;
	background: rgba(255, 255, 255, 0.4);
	border-radius: 100%;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
}

.ripple-animate {
	-webkit-animation: ripple 0.65s linear;
	-moz-animation: ripple 0.65s linear;
	-ms-animation: ripple 0.65s linear;
	-o-animation: ripple 0.65s linear;
	animation: ripple 0.65s linear;
}

@-webkit-keyframes ripple {
	100% {
		opacity: 0;
		-webkit-transform: scale(2.5);
	}
}

@-moz-keyframes ripple {
	100% {
		opacity: 0;
		-moz-transform: scale(2.5);
	}
}

@-o-keyframes ripple {
	100% {
		opacity: 0;
		-o-transform: scale(2.5);
	}
}

@keyframes ripple {
	100% {
		opacity: 0;
		transform: scale(2.5);
	}
}

@keyframes rippleOn {
	0% {
		opacity: 0.5;
	}
	100% {
		opacity: 0;
		transform: scale(13, 13);
	}
}

@keyframes rippleOff {
	0% {
		opacity: 0.5;
	}
	100% {
		opacity: 0;
		transform: scale(13, 13);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.#{$iconPrefix}-spin {
	-webkit-animation-name: spin;
	animation-name: spin;
}

/* Code Highlight */
.docs {
	pre[class*="language-"] {
		padding: 0 !important;
		background-color: #fdfdfd;
		border-left: none;
		@include shadow(none);

		> code {
			border-left: 10px solid lighten($primaryColor, 15%);
			box-shadow: none;
			font-size: 14px;
		}
	}
}

.slider {
  & .p-col.p-col-12 {
    min-height: 60px;
    display: flex;
		flex-direction: column;
		label {
			height: 30px;
		}
	}
	&.disabled > .p-col.p-col-12 > label {
		color: #CCC;
	}
}

.checkbox {
	margin-bottom: 10px;
}

p.disabled {
	color: #CCC;
}

.dropdown {
	& .p-dropdown {
		width: 100%;
	}
}