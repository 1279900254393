@keyframes toolbar_apparition {
  from {
    transform: translateY(-70px);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.layout-wrapper .layout-main .layout-topbar {
  transform: translateY(-70px);
  opacity: 0.5;
  animation: toolbar_apparition 0.25s forwards;
  animation-delay: 0.1s;
}

body {
  .credentials_alert {
    padding: 5px;
    margin: 0 !important;
    letter-spacing: 0;
    font-size: 11px;
    .pi-md-warning {
      width: 25px;
      position: relative;
      top: 2.5px;
    }
  }
  .p-col {
    @media(min-width: 961px) {
      position: relative;
    }
    &.button_handler {
      text-align: right;
      
      @media(max-width: 960px) {
        display: none;
      }
    }
    & .actionBar {
        display: flex;
        transition: opacity 0.25s;
        height: 1.7rem;
        box-shadow: 1px 1px 5px #DEDEDE;
        min-width: 100px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background: rgba(144, 144, 94, 0.1);
        margin: 0.5rem 0.25rem;
        width: 120px;
        @media(max-width: 960px) {
          margin: 8px 0 0 8px;
          display: block;
          width: 100%;
          height: 37px;
          line-height: 20px;
          padding-top: 3px;
          text-align: center;
        }
        & a {
          text-decoration: none;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 2rem;
          width: 2rem;
          font-size: 1.1rem;
          text-align: center;
          transition: font-size 0.25s ease-in-out;
          &:hover {
            font-size: 1.5rem;
          }
          @media(max-width: 960px) {
            font-size: 1.5rem;
            padding: 0 25px;
          }
          span {
            width: inherit;
            max-height: inherit;
          }
        }
      }
    &.post {
      .content_post {
        min-height: 220px;
        @media(max-width: 960px) {
          min-height: 20px;
        }
        padding-top: 1rem;
        border-top: solid 1px #CCC;
      }
      padding: 0.5rem 1rem;
      & h3 {
        min-height: 3rem;
        padding: 1rem 0;
      }
    }
    &.picture {
      display: flex;
      padding: 1.5rem 1rem;
      justify-content: flex-end;
      @media(max-width: 960px) {
        flex-direction: column-reverse;
      }
      & .actions {
        display: flex;
        flex: 1;
        max-width: 125px;
        flex-direction: column;

        &.notify {
          max-width: 100%;
          flex-direction: row;
          justify-content: flex-end;
          .p-button {
            margin: 7px;
          }
        }

        @media(max-width: 960px) {
          flex-direction: row;
          max-width: 100%;
          margin: 0;
        }
        margin: 0 0.5rem;
        & .message__handle {
          cursor: pointer;
          @media(max-width: 960px) {
            height: 50px;
            line-height: 30px;
            padding-top: 6px;
            & .p-message.p-component {
              margin: 0;
            }
          }
          & .p-message.p-component {
            width: 120px !important;
            box-shadow: 2px 2px 5px #DEDEDE;
          }
        }
      }
      & p {
        text-align: right;
        & img {
          max-width: 20rem;
          @media(max-width: 960px) {
            max-width: 100%;
          }
          max-height: 30rem;
          box-shadow: 2px 2px 5px #DEDEDE;
        }
      }
      
    }
  }
}

body {
  .p-menubar {
    .p-menuitem {
      &.selected {
          background-color: #e8e8e8;
          & a.p-menuitem-link {
              color: #000;
              & .p-menuitem-icon {
                color: #000;
              }
          }
      }
    }
  }
}

@keyframes visualizer-appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.card.visualizer {
    width: 100%;
    margin: 10px 0;
    min-height: 60vh;
    @media(max-width: 960px) {
      padding: 10px;
      h1 {
        font-size: 15px;
        margin: 0.5rem 0;
      }
    }
    .react-json-view {
      opacity: 0;
      animation: visualizer-appear 0.1s linear forwards;
      animation-delay: 0.05s;
    }
}

@keyframes dataview-appear {
  from {
    max-height: 1px;
  }
  to {
    max-height: 1000000px;
  }
}

.visualizer-grid {
  min-height: 60vh;
  @media(max-width: 960px) {
    min-height: 40vh;
    & .p-col {
      padding: 10px;
    }
  }
}

body .p-messages .p-messages-close {
  position: relative;
  left: 99%;
}

.p-dialog {
  @media(max-width: 960px) {
    z-index: 10;
    margin-top: 4rem;
    width: 100%;
  }
}

.p-dialog-content {
  max-width: 70vw;
  @media(max-width: 960px) {
    max-width: 100vw;
  }
}

.support form {
  min-height: 75vh;
}

.p-dataview.p-component.p-dataview-list, .support .p-grid, .visualizer-grid, .editPage, .register {
  overflow: hidden;
  max-height: 0px;
  animation: dataview-appear 250s linear forwards;
  animation-delay: 0.25s;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.postsPage, .support, .visualizePage, .notificationsPage {
  opacity: 0;
  animation: appear 0.25s linear forwards;
  animation-delay: 0.1s;
  @media(max-width: 960px) {
    h1 {
      font-size: 20px;
    }
  }
}

@keyframes login-appear {
  from {
    transform: scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.login-panel, .exception-panel {
  transform: scale(0.5);
  opacity: 0;
  animation: login-appear 0.25s ease-out forwards;
  animation-delay: 0.1s;
}

footer {
  width: 100vw;
  height: 50px;
  line-height: 50px;
  text-align: center;
  a {
      text-decoration: underline;
      color: black;
  }
  @media(max-width: 960px) {
    font-size: 11px;
    & .rights {
      display: none;
    }
  }
}

.layout-content {
  min-height: 100vh;
  footer {
    position: relative;
    top: 20px;
  }
}

.postlistcontent {
  width: 100%;
  min-height: 50vh;
  padding: 5px 14px;
  & > .p-grid {
    width: 100%;
  }
}

.support h1 {
  letter-spacing: 0;
}

.button_query {
  float: right;
  position: relative;
  top: -8px;
  @media(max-width: 960px) {
    display: none;
  }
}

.profile_editor {
  @media(max-width: 960px) {
    .buttonsBar {
      display: flex;
      flex-direction: column;
      .p-button {
        margin: 0.5rem 0;
        width: 100%;
      }
    }
  }
}

div.layout-content.postsPage > div.p-grid.button_handler_mobile {
  @media(min-width: 961px) {
    display: none;
  }
  & > div > button {
    width: calc(100% - 15px);
    margin: 0 8px;
  }
}

.notificationCenter {
  margin: 10px;
  ul {
    margin: 20px;
    li {
      padding: 5px;
    }
  }
}